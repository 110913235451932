import { useEffect, useState } from 'react';
import { Row, Col, Card, Tooltip, Button } from 'antd';
import { CensusDto } from '@medone/medonehp-api-client';

import { useAppDispatch } from '../../../../../shared/hooks';
import { formatDateTime } from '../../../../../shared/common/helpers';

import QuickNote from '../components/notes/quick-note';
import ActionButtons from '../components/action-buttons';
import VisitDueBadge from '../components/badges/visit-due';
import ProviderTypeBadge from '../components/badges/provider-type';
import CensusBadges from '../components/badges';
import ScheduledBadge from '../components/badges/scheduled';
import VisitTypeBadge from '../components/badges/visit-type';
import { togglePatientDrawer } from '../slice.patients';
import Skilled from '../components/skilled';

type Props = {
    census: CensusDto[];
};

const PatientTableMobile = ({ census }: Props) => {
    const dispatch = useAppDispatch();
    const [dataSource, setDataSource] = useState<CensusDto[]>(census);

    useEffect(() => {
        setDataSource(census);
    }, [census]);

    return (
        <>
            {dataSource.map((censusItem, idx) => (
                <Card key={`${idx}-${censusItem.patientIntakeId}`} className="patient-card">
                    <h4 className="text-center">
                        <Button
                            type="link"
                            onClick={() => dispatch(togglePatientDrawer(censusItem))}
                            disabled={!censusItem.patientId || !censusItem.patientIntakeId}
                            className={censusItem.dischargeDate != null && 'patient-discharged'}
                        >
                            <Tooltip title={censusItem.dischargeDate != null && `Discharged on ${formatDateTime(censusItem.dischargeDate)}`}>{censusItem.name}</Tooltip>
                        </Button>

                        <Skilled census={censusItem} mobile />
                    </h4>

                    <Row>
                        <Col span={24}>
                            <strong>DOB:</strong> {formatDateTime(censusItem.dateOfBirth, '', 'L', censusItem.age)}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <strong>Room #:</strong> {censusItem.roomNumber}
                        </Col>
                    </Row>

                    <Row className="mt-1">
                        <strong className="mr-1">Visit Due:</strong>

                        <VisitDueBadge
                            patientName={censusItem.name}
                            admissionDate={censusItem.admissionDate}
                            admittedToId={censusItem.admittedToId}
                            patientIntakeId={censusItem.patientIntakeId}
                            isMobile
                        />
                    </Row>

                    <Row>
                        <strong className="mr-1">Visit Type(s):</strong> <VisitTypeBadge patientIntakeId={censusItem.patientIntakeId} />
                    </Row>

                    <Row>
                        <strong className="mr-1">Provider Type:</strong> <ProviderTypeBadge patientIntakeId={censusItem.patientIntakeId} />
                    </Row>

                    <Row className="mt-1">
                        <ScheduledBadge patientIntakeId={censusItem.patientIntakeId} />
                    </Row>

                    <Row className="mt-1">
                        <CensusBadges patientIntakeId={censusItem.patientIntakeId} patientTypeIds={censusItem.patientTypeIds} canReturnFromED={censusItem.canReturnFromED} />
                    </Row>

                    <Row>
                        <Col span={24} className="text-center mt-1">
                            <ActionButtons census={censusItem} isMobile />
                        </Col>

                        <Col span={24} className="text-center mt-1">
                            <QuickNote
                                patientId={censusItem.patientId}
                                facilityId={censusItem.admittedToId}
                                patientName={censusItem.name}
                                mobile
                                patientIntakeId={censusItem.patientIntakeId}
                            />
                        </Col>
                    </Row>
                </Card>
            ))}
        </>
    );
};

export default PatientTableMobile;
